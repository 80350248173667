<template>
  <div class="container" v-if="posterList.length > 0">
    <poster :poster-list="posterList" :page-path="pagePath" :module-key="moduleKey" :token="token" :time-format="3" />
  </div>
</template>

<script>
import Poster from '../../../components/Poster/Index'

export default {
  name: "Double12MWPoster",
  components: {
    Poster
  },
  data() {
    return {
      token: this.$route.query.token,
      posterList: [

      ],
      pagePath: this.baseHost + '/',
      moduleKey: ''
    }
  },
  created() {
    this.getMWActTime();
    this.moduleKey = this.token ? 'd9e26eba-ea4e-442e-b3a9-7ebf54c4920a' : 'gzhDJKcx20211212'
  },
  methods: {
    //获取双12蔓薇活动时间
    getMWActTime() {
      this.get("/OrderGoods/Activity/GetHoursAndNums", "", 2).then(json => {
        if (json.code === 1) {
          let { endTimes } = json.response;
          let nowTime = +new Date();
          endTimes = endTimes * 1000;
          let item = {
            backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211208103452290.png',
            tempBgImage: null,
            miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20201125150355388.png',
            tempPoster: '',
            textColor: '#111111',
            textPoint: [430, 1183],
            wxCode: { width: 188, point: [46, 1110] }
          }
          if (nowTime > endTimes) {
            item.backgroundImage = 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211208103943836.png';
          }
          this.posterList.push(item);
        }
      })
    }
  }
}
</script>

<style scoped>
</style>